import { Card, Container, Row, Col, Image } from "react-bootstrap";
import antmanImage from "../assets/images/superhero/nurul.jpeg";
import avengerImage from "../assets/images/superhero/raini.jpg";
import batmanImage from "../assets/images/superhero/YESSY.jpeg";

const SuperHero = () => {
  return (
    <div>
      <Container>
        <br />
        <h1 className="text-white">PALING BANYAK DI PESAN</h1>
        <br />
        <Row>
          <Col md={4} className="movieWrapper" id="superhero">
            <Card className="movieImage">
              <Image src={antmanImage} alt="Dune Movies" className="images" />
              <div className="bg-dark">
                <div className="p-2 m-1 text-white">
                  <Card.Title className="text-center">NURUL</Card.Title>
                  <Card.Text className="text-center">
                    Nurul Perempuan Cantik Asal Jakarta Umur 19 Tahun
                  </Card.Text>
                  <Card.Text className="text-center">⭐️⭐️⭐️⭐️⭐️</Card.Text>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={4} className="movieWrapper">
            <Card className="movieImage">
              <Image src={avengerImage} alt="Dune Movies" className="images" />
              <div className="bg-dark">
                <div className="p-2 m-1 text-white">
                  <Card.Title className="text-center">RAINI ALIYAH</Card.Title>
                  <Card.Text className="text-center">
                    Raini Aliyah Perempuan Cantik Asal Jakarta Umur 22 Tahun
                  </Card.Text>
                  <Card.Text className="text-center">⭐️⭐️⭐️⭐️⭐️</Card.Text>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={4} className="movieWrapper">
            <Card className="movieImage">
              <Image src={batmanImage} alt="Dune Movies" className="images" />
              <div className="bg-dark">
                <div className="p-2 m-1 text-white">
                  <Card.Title className="text-center">YESSY</Card.Title>
                  <Card.Text className="text-center">
                    Yessy Perempuan Cantik Asal Bogor Umur 21 Tahun
                  </Card.Text>
                  <Card.Text className="text-center">⭐️⭐️⭐️⭐️⭐️</Card.Text>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SuperHero;

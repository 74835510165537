import { Navbar, Container, Nav } from "react-bootstrap";

const NavigationBar = () => {
  return (
    <div>
      <Navbar variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <img
              src="https://i.ibb.co/CKzCC5k/livebokep.png"
              alt="Logo"
              width="150"
              height="50"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Nav>
            <Nav.Link href="#trending">VCS 50RB</Nav.Link>
            <Nav.Link href="#superhero">TOP TALENT</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavigationBar;

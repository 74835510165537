import { Card, Container, Row, Col, Image } from "react-bootstrap";
import duneImage from "../assets/images/trending/YESSY.jpeg";
import everythingImage from "../assets/images/trending/nurul.jpeg";
import infiniteImage from "../assets/images/trending/raini.jpg";
import jokerImage from "../assets/images/trending/gysela.jpg";
import lightyearImage from "../assets/images/trending/abigail.jpg";
import morbiusImage from "../assets/images/trending/della.jpg";

const Trending = () => {
  return (
    <div>
      <Container>
        <br />
        <h1 className="text-white">RATE VCS 50RB</h1>
        <br />
        <Row>
          <Col md={4} className="movieWrapper" id="trending">
            <Card className="movieImage">
              <Image src={duneImage} alt="Dune Movies" className="images" />
              <div className="bg-dark">
                <div className="p-2 m-1 text-white">
                  <Card.Title className="text-center">YESSY</Card.Title>
                  <Card.Text className="text-center">
                    Yessy Perempuan Cantik Asal Bogor Umur 21 Tahun
                  </Card.Text>
                  <Card.Text className="text-center">
                    Durasi VCS 30 Menit
                  </Card.Text>
                  <Card.Text className="text-center">
                    <a href="https://t.ly/wayessy">VCS SEKARANG</a>
                  </Card.Text>
                  <Card.Text className="text-center">⭐️⭐️⭐️⭐️⭐️</Card.Text>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={4} className="movieWrapper">
            <Card className="movieImage">
              <Image
                src={everythingImage}
                alt="Dune Movies"
                className="images"
              />
              <div className="bg-dark">
                <div className="p-2 m-1 text-white">
                  <Card.Title className="text-center">NURUL</Card.Title>
                  <Card.Text className="text-center">
                    Nurul Perempuan Cantik Asal Jakarta Umur 19 Tahun
                  </Card.Text>
                  <Card.Text className="text-center">
                    Durasi VCS 30 Menit
                  </Card.Text>
                  <Card.Text className="text-center">
                    <a href="https://t.ly/wanurul">VCS SEKARANG</a>
                  </Card.Text>
                  <Card.Text className="text-center">⭐️⭐️⭐️⭐️⭐️</Card.Text>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={4} className="movieWrapper">
            <Card className="movieImage">
              <Image src={infiniteImage} alt="Dune Movies" className="images" />
              <div className="bg-dark">
                <div className="p-2 m-1 text-white">
                  <Card.Title className="text-center">RAINI ALIYAH</Card.Title>
                  <Card.Text className="text-center">
                    Raini Aliyah Perempuan Cantik Asal Jakarta Umur 22 Tahun
                  </Card.Text>
                  <Card.Text className="text-center">
                    Durasi VCS 30 Menit
                  </Card.Text>
                  <Card.Text className="text-center">
                    <a href="https://t.ly/waraini">VCS SEKARANG</a>
                  </Card.Text>
                  <Card.Text className="text-center">⭐️⭐️⭐️⭐️⭐️</Card.Text>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={4} className="movieWrapper">
            <Card className="movieImage">
              <Image src={jokerImage} alt="Dune Movies" className="images" />
              <div className="bg-dark">
                <div className="p-2 m-1 text-white">
                  <Card.Title className="text-center">GYSELA</Card.Title>
                  <Card.Text className="text-center">
                    Gysela Perempuan Cantik Asal Bandung Umur 18 Tahun
                  </Card.Text>
                  <Card.Text className="text-center">
                    Durasi VCS 30 Menit
                  </Card.Text>
                  <Card.Text className="text-center">
                    <a href="https://t.ly/wagysela">VCS SEKARANG</a>
                  </Card.Text>
                  <Card.Text className="text-center">⭐️⭐️⭐️</Card.Text>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={4} className="movieWrapper">
            <Card className="movieImage">
              <Image
                src={lightyearImage}
                alt="Dune Movies"
                className="images"
              />
              <div className="bg-dark">
                <div className="p-2 m-1 text-white">
                  <Card.Title className="text-center">ABIGAIL</Card.Title>
                  <Card.Text className="text-center">
                    Abigail Perempuan Cantik Asal Tangerang Umur 22 Tahun
                  </Card.Text>
                  <Card.Text className="text-center">
                    Durasi VCS 30 Menit
                  </Card.Text>
                  <Card.Text className="text-center">
                    <a href="https://t.ly/waabigael">VCS SEKARANG</a>
                  </Card.Text>
                  <Card.Text className="text-center">⭐️⭐️⭐️⭐️</Card.Text>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={4} className="movieWrapper">
            <Card className="movieImage">
              <Image src={morbiusImage} alt="Dune Movies" className="images" />
              <div className="bg-dark">
                <div className="p-2 m-1 text-white">
                  <Card.Title className="text-center">DELLA</Card.Title>
                  <Card.Text className="text-center">
                    Della Perempuan Cantik Asal Palembang Umur 20 Tahun
                  </Card.Text>
                  <Card.Text className="text-center">
                    Durasi VCS 30 Menit
                  </Card.Text>
                  <Card.Text className="text-center">
                    <a href="https://t.ly/wadella">VCS SEKARANG</a>
                  </Card.Text>
                  <Card.Text className="text-center">⭐️⭐️⭐️</Card.Text>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Trending;
